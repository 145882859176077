import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let constantRoutes = []

let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);


if (!isMobile) {
    constantRoutes = [
        {
            path: "/",
            name: "index",
            component: (resolve) => require(["@/views/pc/index"], resolve),
            meta: {
                keepAlive: false,
                title: '新湘雅医院'
            }
        },
        {
            path: "/index",
            name: "index",
            component: (resolve) => require(["@/views/pc/index"], resolve)
        },
        {
            path: "/custody",
            name: "custody",
            component: (resolve) => require(["@/views/pc/custody"], resolve)
        },
        {
            path: "/service",
            name: "service",
            component: (resolve) => require(["@/views/pc/service"], resolve)
        }
    ];
}else{
    constantRoutes = [
        {
            path: "/",
            name: "index",
            component: (resolve) => require(["@/views/mobile/index"], resolve),
            meta: {
                keepAlive: false,
                title: '，新湘雅医院'
            }
        },
        {
            path: "/index",
            name: "index",
            component: (resolve) => require(["@/views/mobile/index"], resolve)
        },
        {
            path: "/custody",
            name: "custody",
            component: (resolve) => require(["@/views/mobile/custody"], resolve)
        },
        {
            path: "/service",
            name: "service",
            component: (resolve) => require(["@/views/mobile/service"], resolve)
        }
    ];
}

const router = new VueRouter({
    mode: "hash",
    routes: constantRoutes,
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }else{
        document.title = '新湘雅医院'
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})

export default router;
