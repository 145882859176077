<template>
    <div class="header" v-if="!isMobile">
        <div class="left">
            <el-image :src="logo" />
        </div>
        <div class="right">
            <el-button plain class="login" @click="login()">立即登录</el-button>
            <el-button type="text" class="register" @click="register()">注册</el-button>
        </div>
    </div>
    <div class="top" v-else>
        <div class="left">
            <el-image :src="logo" />
        </div>
        <!-- <div class="right">
            <el-button size="mini" plain class="login" @click="login()">立即登录</el-button>
            <el-button size="mini" type="text" class="register" @click="register()">注册</el-button>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "index",

    data() {
        return {
            logo: require('@/assets/logo.png'),
            isMobile: false,
        }
    },

    mounted() {
        this.isMobile = this.$isMobile();
    },

    methods: {
        login() {
            this.$toLogin();
        },

        register() {
            this.$toRegister();
        }
    }
}
</script>

<style lang="scss" scoped>
    .header {
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 8px 110px;
        .left{
            height: 54px;
            .el-image{
                height: 54px;
            }
        }
        .right{
            margin-left: auto;
            .login{
                color: #176BFF;
                font-size: 16px;
                border-color: #176BFF;
            }
            .register{
                color: #333333;
                font-size: 16px;
            }
        }
    }
    .top{
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 8px 30px;
        .left{
            .el-image{
                height: 30px;
            }
        }
        .right{
            margin-left: auto;
        }
    }
</style>
