export default {
    install: function(Vue, options) {
        Vue.prototype.$toLogin = function () {
            window.open('https://admin-xiangya.colofoo.com/', '_blank')
        }

        Vue.prototype.$toRegister = function () {
            window.open('https://admin-xiangya.colofoo.com/', '_blank')
        }

        Vue.prototype.$isMobile = function () {
            return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        }
    }
}
