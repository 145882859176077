<template>
    <div class="footer" v-if="!isMobile">
        <div class="content">
            <div class="title">开启智慧健康家庭管理新模式</div>
            <div class="login-register">
                <el-button size="medium" type="primary" class="login" @click="login()">立即登录</el-button>
                <el-button size="medium" type="text" class="register" @click="register()">去注册</el-button>
            </div>
        </div>
    </div>
    <div class="down" v-else>
        <div class="content">
            <div class="title">开启智慧健康家庭管理新模式</div>
            <div class="login-register">
                <el-button size="mini" type="primary" class="login" @click="login()">立即登录</el-button>
                <el-button size="mini" type="text" class="register" @click="register()">去注册</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",

    data(){
        return {
            isMobile: false,
        }
    },

    mounted() {
        this.isMobile = this.$isMobile();
    },

    methods: {
        login() {
            this.$toLogin();
        },

        register() {
            this.$toRegister();
        }
    }
}
</script>

<style lang="scss" scoped>
    .footer{
        text-align: center;
        padding: 50px 110px;
        background-image: url("@/assets/images/footer.png");
        background-repeat: no-repeat;
        background-size: cover;
        .content{
            .title{
                font-size: 34px;
                font-weight: 400;
                color: #040415;
                line-height: 48px;
            }
            .login-register{
                margin-top: 40px;
                font-weight: 600;
                .login{
                    border-radius: 0;
                    background: #176BFF;
                }
                .register{
                    width: 98px;
                    color: #176BFF;
                    border-radius: 0;
                    border: 1px solid #176BFF;
                }
            }
        }
    }
    .down{
        text-align: center;
        padding: 28px 0;
        background-image: url("@/assets/images/footer.png");
        background-repeat: no-repeat;
        background-size: cover;
        .content{
            .title{
                font-size: 16px;
                font-weight: 400;
                color: #040415;
                line-height: 22px;
            }
            .login-register{
                margin-top: 10px;
                font-weight: 600;
                .login{
                    border-radius: 0;
                    background: #176BFF;
                }
                .register{
                    width: 80px;
                    color: #176BFF;
                    border-radius: 0;
                    border: 1px solid #176BFF;
                }
            }
        }
    }
</style>
